import { Link } from 'gatsby'
import React from 'react'

import '../theme/thanks.sass'

const text = [
  'お問い合わせありがとうございます。',
  '確認のメールを送信しました。',
  '確認のメールが届かない場合、お返事のメールも届きません。',
  'その場合、メールアドレスをご確認頂き、再度送信頂くか、',
  'Facebook よりお問い合わせください。',
]

const ThanksPage: React.FC = () => {
  return (
    <div className="thanksPage">
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {text.map((t) => (
          <p key={t}>{t}</p>
        ))}
      </div>
      <Link to="/" style={{ paddingTop: 30, fontSize: 20 }}>
        ホームへ
      </Link>
    </div>
  )
}

export default ThanksPage
